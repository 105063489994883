<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="货物" prop="goodsId">
      <GoodsChoose v-model="formData.goodsId" />
    </FormItem>
    <FormItem label="数量" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" />
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
import GoodsChoose from '../../../base/choose.vue'
export function getDefault () {
  return {
    id: null,
    name: null,
    goodsGroupId: null,
    goodsId: null,
    volume: null,
    remark: null
  }
}

const rules = Object.freeze({
  goodsId: [
    { required: true, message: '请选择货物' }
  ],
  volume: [
    { required: true, message: '请输入数量' }
  ]
})

export default {
  components: { GoodsChoose },
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      formData: this.data || getDefault(),
      rules
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
