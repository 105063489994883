<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="名称" prop="name">
      <Input v-model="formData.name" placeholder="请输入名称" />
    </FormItem>
    <FormItem label="状态" prop="status">
      <Select v-model="formData.status" placeholder="请选择状态">
        <Option v-for="item in scmGoodsProduceStandardStatusList" :value="item.value" :key="item.value" border>{{ item.label }}</Option>
      </Select>
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
export function getDefault () {
  return {
    id: null,
    name: null,
    status: 0,
    remark: null
  }
}

const rules = Object.freeze({
  name: [
    { required: true, message: '请输入名称' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      formData: this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(),
      rules
    }
  },
  computed: {
    scmGoodsProduceStandardStatusList () {
      return this.$store.getters.scmGoodsProduceStandardStatusList
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
